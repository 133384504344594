import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';

import './page-14.css';

const PageFourteen = forwardRef((props, ref) => (
  <>
    <SEO title="Page 14" />
    <main className="page page14">
      <div className="page__image-container">
        <StaticImage
          alt="Two sockpickers called Muddler and Hummer, sorting the socks and looking after the machines"
          aspectRatio={7474 / 5399}
          className="page__image page__image-border"
          height={1000}
          layout="constrained"
          loading="eager"
          placeholder="tracedSVG"
          src="../../images/page-14.png"
        />
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text">
          <p>
            Amanda was so amazed as Jimbugs took her around and introduced her to some of the others. There was
            Muddler, who was in charge of sorting the socks and Hummer who looked after the machines.
          </p>
          <p>
            Some others, whose names Amanda couldn’t remember, said &ldquo;hello&rdquo; too.
          </p>
          <p>
            Once they got over their surprise they were all very friendly!
          </p>
        </div>
      </div>
      <PreviousPage to="/page-13" />
      <NextPage to="/page-15" />
    </main>
  </>
));

const PageFourteenContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageFourteen ref={textRef} />;
};

export default PageFourteenContainer;
